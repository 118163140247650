
.button-list {
    margin: 0 0 $block-margin;

    li {
        margin-bottom: ($block-padding / 2);

        &:last-child {
            margin-bottom: 0;
        }

        a {
            @extend .button;
            background: $color-lightgrey;
            color: $color-text;
            
            &:hover {
                background: $color-primary;
                color: $color-white;
            }
        }
    }

    &:last-child {
        margin: 0;
    }
}


// Default .button class
.button {
    @extend %button;
    display: inline-block;
    padding: 0.5rem 3rem;
    border-radius: $border-radius;
    transition: $transition;

    &:hover {
        @extend %hover;
    }

    a {
        color: inherit;
        transition: inherit;
    }
}
