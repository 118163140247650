
//==========  Site structure  ==========//

// the max-width the site will be
$max-width: 3000px;

// the padding around the body on devices
// only used up to the max-width viewport
$site-padding: 7.5vw;

// fixed padding for inner content containers
$inner-padding: 2rem;

// set height of "hero" elements
// actually in seperate containers, so we create illusion of hero
$hero-height: 15vh;

// set a minimum pixel height for these elements
// help prevent crazy proportioned layouts
// we'll scale this base value across our media queries for different devices
// $min-hero-height: 350px;
$min-hero-height: 300px;

// $calc-hero-height-small: 350px;

$calc-hero-height: calc(#{$min-hero-height} + #{$hero-height});
// $calc-hero-height: 50vh;

$min-hero-height-home: $min-hero-height + 200;

$calc-hero-height-home: calc(#{$min-hero-height-home} + #{$hero-height});


// set height of user action buttons
$button-height: 60px;

// height of the cms admin bar
$admin-bar: 30px;

// set the aspect ratio of any videos
$player: (100% / 16) * 9;


//==========  Blocks layout  ==========//

// used throughout our modules
// keep spacing between elements consistent

$block-padding: 1.5rem;
$block-margin: 4.5rem;
