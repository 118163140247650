
.centre .block {

	li a[href*=".pdf"],
	li a[href*=".docx"] {
		@extend .button;
		margin-bottom: ($block-margin / 2);
	}

	iframe {
		max-width: 100%!important;
	}

}

// Content styles
.block-text {

	// Default paragraphs
	p {
		margin: 0 0 $paragraph-margin;

		&.intro {
			font-weight: $font-reg;

			@media #{$mobileXL} {
				font-size: $h5;
			}
		}
	}

	* + ul[class] {
		@extend %reset-ul;
		margin: 0 0 $paragraph-margin;
	}

	// New default <ul> lists
	* + ul {
		@extend %default-ul;
	}

	// New default <ol> lists
	* + ol {
		@extend %default-ol;
	}

	+ .block {
		padding-top: ($block-margin);
		border-top: 2px solid $color-white;
	}

}

.type-default,
.type-office,
.type-profile {

	// @media #{$nav-breakpoint} {

	// 	.block-section {

	// 		.block-header {
	// 			position: relative;
	// 			min-height: $calc-hero-height;
	// 			margin: 0 0 0 $columns-custom-left-small;
	// 		}

	// 		.author-image,
	// 		.author-meta {
	// 			position: relative;
	// 			top: 10vh;
	// 		}

	// 		.title {
	// 			@include centreY(absolute);
	// 			@include coverX(none);
	// 		}

	// 		.content {
	// 			padding: ($block-padding * 2) 0 0;
	// 		}

	// 	}

	// }
}


.mfg-advert-logo {

	p {
		margin-top: $block-margin - 2.5rem;

		&:first-child {
			margin-top: 0;
		}
	}
}

.iframe {
	width: 100%;
	height: auto;
	aspect-ratio: 16 / 9;
}