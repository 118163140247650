@keyframes loading {
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    opacity: 0; } }

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background: transparent; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: none;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: none;
  padding: 0;
  margin: 0; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

.hidden, .cms-toolbar, .header .menu-toggle,
.header .nav, .header .calls-to-action, .header .inner *, .hero .inner *, .column.right, .column.left, .pre-footer, .office-links, .search-container, .social-icons,
.footer-links, .cookies, .mobile-ui {
  display: none; }

@keyframes hippyshake {
  0% {
    transform: translateX(2px); }
  20% {
    transform: translateX(0); }
  40% {
    transform: translateX(-2px); }
  60% {
    transform: translateX(0); }
  80% {
    transform: translateX(2px); }
  100% {
    transform: translateX(0); } }

.block-text * + ul, .block-text * + ol {
  margin: 0 0 2rem;
  padding-left: 20px; }

.block-text * + ul {
  list-style: disc; }

.block-text * + ol {
  list-style: decimal; }

ul, ol, .block-text * + ul[class] {
  list-style: none;
  padding: 0;
  margin: 0; }

.button, .centre .block li a[href*=".pdf"],
.centre .block li a[href*=".docx"], .button-list li a {
  border: none;
  border-radius: 8px;
  background: #f17e0d;
  color: #fff;
  text-align: center; }

.button:hover, .centre .block li a[href*=".pdf"]:hover,
.centre .block li a[href*=".docx"]:hover, .button-list li a:hover {
  background: #141414;
  color: #fff; }

html {
  font-size: 95%; }
  @media only screen and (min-width: 40em) {
    html {
      font-size: 100%; } }

body {
  color: #141414;
  font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.7; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0 0 2.25rem;
  color: #141414;
  font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.1; }

h1, .h1 {
  font-size: 2.6rem; }

h2, .h2 {
  font-size: 2.3rem; }

h3, .h3 {
  font-size: 2rem; }

h4, .h4 {
  font-size: 1.7rem; }

h5, .h5 {
  font-size: 1.4rem; }

h6, .h6 {
  font-size: 1.2rem; }

p {
  margin: 0; }

strong {
  font-weight: 500; }

hr {
  border: 2px solid #fff; }

a {
  color: #f17e0d;
  text-decoration: none;
  transition: all 0.2s ease-out; }
  a:hover {
    color: #141414; }

.column .table-scroll {
  overflow: scroll;
  margin: 0 0 2rem; }
  .column .table-scroll table {
    width: 100%; }
    .column .table-scroll table th {
      padding: 1rem 1.5rem;
      border: 2px solid #d9710c;
      background: #f17e0d;
      color: #fff;
      font-family: "Roboto", "Helvetica Neue", "Arial", sans-serif; }
    .column .table-scroll table td {
      padding: 1.5rem;
      border: 2px solid #f6f6f6;
      vertical-align: top; }
    .column .table-scroll table p {
      margin: 0; }

.centre .block li a[href*=".pdf"],
.centre .block li a[href*=".docx"] {
  margin-bottom: 2.25rem; }

.centre .block iframe {
  max-width: 100% !important; }

.block-text p {
  margin: 0 0 2rem; }
  .block-text p.intro {
    font-weight: 400; }
    @media only screen and (min-width: 40em) {
      .block-text p.intro {
        font-size: 1.4rem; } }

.block-text * + ul[class] {
  margin: 0 0 2rem; }

.block-text + .block {
  padding-top: 4.5rem;
  border-top: 2px solid #fff; }

.mfg-advert-logo p {
  margin-top: 2rem; }
  .mfg-advert-logo p:first-child {
    margin-top: 0; }

.iframe {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9; }

.button-list {
  margin: 0 0 4.5rem; }
  .button-list li {
    margin-bottom: 0.75rem; }
    .button-list li:last-child {
      margin-bottom: 0; }
    .button-list li a {
      background: #f6f6f6;
      color: #141414; }
      .button-list li a:hover {
        background: #f17e0d;
        color: #fff; }
  .button-list:last-child {
    margin: 0; }

.button, .centre .block li a[href*=".pdf"],
.centre .block li a[href*=".docx"], .button-list li a {
  display: inline-block;
  padding: 0.5rem 3rem;
  border-radius: 8px;
  transition: all 0.3s ease-out; }
  .button a, .centre .block li a[href*=".pdf"] a,
  .centre .block li a[href*=".docx"] a, .button-list li a a {
    color: inherit;
    transition: inherit; }

.hidden, .cms-toolbar, .header .menu-toggle,
.header .nav, .header .calls-to-action, .header .inner *, .hero .inner *, .column.right, .column.left, .pre-footer, .office-links, .search-container, .social-icons,
.footer-links, .cookies, .mobile-ui {
  display: none; }

* {
  color: #141414;
  background: none; }

.wrapper {
  padding-bottom: 0 !important;
  padding: 2rem; }

.hero .inner .company-logo-container,
.hero .inner .company-logo-link,
.hero .inner .company-logo {
  display: block; }

.hero .inner .company-logo-container {
  min-height: 0; }

.hero .inner .company-logo {
  position: static;
  transform: none;
  width: 250px;
  max-width: none;
  margin-bottom: 3rem; }

.hero .inner .profile-image-wrapper,
.hero .inner .profile-image {
  display: block; }

.hero .inner .profile-image-wrapper {
  margin-bottom: 3rem; }

.mfg-accreditations {
  margin: 4.5rem 0; }
  .mfg-accreditations li {
    display: inline-block;
    margin-right: 10px; }
    .mfg-accreditations li img {
      max-height: 100px; }

#ccc-notify {
  display: none !important; }
