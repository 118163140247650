
//==========  Mobile UI  ==========//

// define the number of tabs for mobile ui
$ui-panels: 4;

// set the height of the tab bar
$ui-height: 60px;

// set the inner padding of each panel
$ui-panel-padding: 7.5vw;

// set icon size on ui buttons
$ui-button-icon-width: 22px;

// set the mobile ui button font size
$ui-button-font-size: $font-size-body - 0.3;

// set icon size on close button
$ui-close-icon-width: 50px;


// set default, hover and active colours on ui buttons
$ui-button-color: 			$color-white;
$ui-button-color-hover: 	$color-white;
$ui-button-color-active: 	$color-white;

// set default, hover and active backgrounds on ui buttons
$ui-button-bg-color: 		$color-black-light;
$ui-button-bg-hover: 		darken($ui-button-bg-color, 5%);
$ui-button-bg-active: 		$color-primary;

// set default, hover and active backgrounds on ui button icons
$ui-button-icon-color: 		$color-primary;
$ui-button-icon-hover: 		$color-primary;
$ui-button-icon-active: 	$color-white;

// set default, and hover fills on ui close button icons
$ui-close-bg-color:   		rgba($color-black, 0.4);
$ui-close-bg-hover:   		rgba($color-black, 0.7);
$ui-close-icon-color: 		$color-white;


// set default colour for panel text
$ui-panel-color: 			$color-white;

// set default background colour for all panels
// alternatively, set individually below
// selectors are grouped by colour before output
$ui-panel-bg: 				$color-black;

// sets individual background colours on ui panels
// if this doesn't exist, no styles are output (so can be commented out if not required)
// no specific style is output if colour matches the default $ui-panel-bg variable
// selectors are grouped by colour before output
$ui-panel-bgs: (
	search 	: $ui-panel-bg,
	map 	: $ui-panel-bg,
	phone 	: $ui-panel-bg,
	enquiry : $color-primary,
	share 	: $ui-panel-bg,
	social 	: $ui-panel-bg,
	menu 	: $ui-panel-bg
);



//==========  Mobile UI Menu  ==========//

// define border styles for menu links
// set to 0 or none if not required (no output)
$ui-menu-border: $border-size $border-style rgba($color-white, 0.1);
// $ui-menu-border: none;

// define button widths for menu up-down level buttons
$ui-menu-button-width: 15vw;

// set font size for menu links
$ui-menu-font-size: $font-size-body + 0.2;

// set font size for menu title links
$ui-menu-title-font-size: $h1;



// set default, hover and active colours on ui menu links
$ui-menu-color: 			$color-white;
$ui-menu-color-hover: 		$color-white;
$ui-menu-color-active: 		$color-white;

// set default, hover and active backrounds on ui menu links
$ui-menu-bg-hover: 			rgba($color-white, 0.05);
$ui-menu-bg-active: 		rgba($color-white, 0.05);

// set default, and hover backgrounds on ui menu buttons
$ui-menu-button-color: 		transparent;
$ui-menu-button-hover: 		rgba($color-white, 0.05);

// set default, and hover fills on ui menu button icons
$ui-menu-button-icon-color: $color-primary;
$ui-menu-button-icon-hover: $color-primary;

//==========  Mobile UI Panels  ==========//

// Loading animation
$animation-speed: 1000ms;
$dot-size: 14px;
$dot-color: $color-white;

@mixin loading($delay: 0ms) {
	animation: loading $animation-speed ease infinite $delay;
}

@keyframes loading {

	50% {
		transform: scale(1);
		opacity: 1;
	}
	
	100% {
		opacity: 0;
	}

}
